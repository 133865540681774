import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { BasicProps } from './basic.model';
import BasicStyles from './basic.module.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import PostsDistributive from '../../components/posts-distributive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';

// FIXME: Add typing for Gatsby GraphQL queries.
// TODO: CREATE COMPONENT MODEL
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const BasicTemplate: React.FC<any> = ({ data }): ReactElement => {
  const { t } = useTranslation();
  const siteTitle = data.site.siteMetadata.title;
  const category = data.strapiCategories;
  const posts = data.allStrapiRecipes.edges;

  return (
    <Layout title={siteTitle}>
      <SEO title={category.name} description={category.description} keywords={[category.name]} />
      <div className={BasicStyles.section_profile}>
        <div
          className={`${BasicStyles.profile_wrap} ${BasicStyles.is_cover}`}
          style={{
            backgroundImage: `url(${category.thumbnail ? category.thumbnail.childImageSharp.fluid.src : ''})`,
          }}
        >
          <h1>{category.name}</h1>
          <div className={BasicStyles.posts_number}>
            <FontAwesomeIcon icon={faConciergeBell} size="lg" />
            <span>
              {data.allStrapiRecipes.totalCount} {t('BASIC_TEMPLATE_RECIPES_TITLE')}
            </span>
          </div>
          <h2>{category.description}</h2>
        </div>
      </div>
      <div className={`${BasicStyles.section_loop} ${BasicStyles.wrap}`}>
        <div className={`${BasicStyles.items_wrap} ${BasicStyles.flex}`}>
          <PostsDistributive posts={posts} />
        </div>
      </div>
    </Layout>
  );
};

export default BasicTemplate;
export const pageQuery = graphql`
  query categoryRecipes($id: String!, $strapiId: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    strapiCategories(id: { eq: $id }) {
      id
      name
      description
      thumbnail {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
    allStrapiRecipes(
      filter: { categories: { elemMatch: { id: { eq: $strapiId } } } }
      sort: { order: DESC, fields: source___date }
    ) {
      totalCount
      edges {
        node {
          title
          url_video
          published_at(formatString: "DD/MM/YYYY")
          chef {
            alias
            avatar {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            path
          }
          fields {
            path
          }
          localImage {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          with_custom_thumbnail
          custom_thumbnail {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          categories {
            name
            path
            id
          }
          difficulty {
            name
          }
        }
      }
    }
  }
`;
